import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { LoginService } from '../services/login.service';
// import { LoginService } from '../services/login.service';

declare var $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  show: boolean;
  pwd: boolean;
  loginForm: FormGroup;
  loading: boolean;
  errmsg: any;

  constructor(public _EncryptDecryptService: EncryptDecryptService, public route: Router, public dialog: MatDialog, public _loginService: LoginService, public router: ActivatedRoute, private recaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit() {

    this.createForm()

    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
    });

  }

  password() {
    this.show = !this.show;
    this.pwd = !this.pwd;
  }

  createForm() {
    this.loginForm = new FormGroup({
      uname: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    })
  }

  submit() {
    console.log('this,loginForm.value update', this.loginForm.value)
    this.loading = true;
    let loginDetails = {
      email: this.loginForm.value.uname,
      password: this._EncryptDecryptService.encrypt(this.loginForm.value.password)
    }

    if (this.loginForm.valid) {

      this.recaptchaV3Service.execute('importantAction').subscribe((token) => {
        console.log("token in component", token)
        // this._loginService.login(loginDetails, token).subscribe((data) => {
        //   console.log("data", data)
        // })
        this._loginService.login(loginDetails, token).subscribe(data => {

          console.log("data of login user", data);

          this.loading = false;
          this.route.navigateByUrl('dashboard')
          // this._loginService.sendLoggedInUserToAppUser(data)
          // console.log("getting item", JSON.parse(localStorage.getItem('currentUser')))
          // let currentLoggedIn = JSON.parse(localStorage.getItem('currentUser'))
          // // let user = JSON.parse(localStorage.getItem('userRoles'))
          // const user = this._loginService.userRolesValue;
          // // console.log("login success resonse:", temp);
          $(".main").removeAttr('style');

          $(document).ready(function () {
            $(".sidebar").show()
            $("#topnav").show()
            $(".sidebar").toggleClass('sidebar--Collapse')
          });



          $(document).ready(function () {
            $(".sidebar").toggleClass('sidebar--Collapse')
          });
        }, err => {
          this.loading = false;
          console.log('error while login', err.status);
          // if(err.status === 0){
          //   this.route.navigate(['/server-error',{maintenance:'maintenance'}])
          // }
          this.errmsg = err.error.message;
        });
      })
    } else {
      console.log('Login form is not valid!!');
    }
  }

}
