import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
declare var $;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  show: boolean;
  pwd: boolean;
  show1: boolean;
  pwd1: boolean;
  show2: boolean;
  pwd2: boolean;

  constructor(public dialogRef: MatDialogRef<ResetPasswordComponent>) {

    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
    });

  }

  ngOnInit() {
  }

  currentPassword() {
    this.show = !this.show;
    this.pwd = !this.pwd;
  }
  newPassword() {
    this.show1 = !this.show1;
    this.pwd1 = !this.pwd1;
  }

  confirmPassword() {
    this.show2 = !this.show2;
    this.pwd2 = !this.pwd2;
  }
}
