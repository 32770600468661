import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../services/clients.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  currentUser: any;
  loading
  clients = []
  constructor(
    public _clientService: ClientsService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('superAdmin'))
  }

  ngOnInit() {
    console.log("currentUser", this.currentUser)
    this.loading = true;
    this.getClients()


  }



  // API CALL


  getClients() {
   
    this._clientService.getClients().subscribe(clients => {
      console.log('clients from service in component', clients)
      this.clients = clients;
      this.loading = false;
      // this.updateData(clients)
    })
  }

}
