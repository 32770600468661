import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { ClientsService } from '../services/clients.service';
import { DeleteModelComponent } from '../common/delete-model/delete-model.component';
import { Observable } from 'rxjs';
declare var $;

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  show: boolean;
  pwd: boolean;
  show1: boolean;
  pwd1: boolean;
  addClientForm
  selectedModule
  modules = JSON.parse(localStorage.getItem('superAdmin')).modules
  //  [
  //   {
  //     key: 'Training Job',
  //     value: 'trainingJob'
  //   },
  //   {
  //     key: 'Medical Job',
  //     value: 'medicalJob'
  //   },
  //   {
  //     key: 'Staff',
  //     value: 'staff'
  //   },
  //   {
  //     key: 'Timesheet',
  //     value: 'timesheet'
  //   },
  //   {
  //     key: 'My documents',
  //     value: 'documents'
  //   }
  // ]
  isloading: boolean;
  loading
  isDisable = false
  match = false
  passwordMismatch

  constructor(
    public _EncryptDecryptService: EncryptDecryptService,
    public _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // public _learnerService: LearnerService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public _clientService: ClientsService
  ) { }

  ngOnInit() {

    console.log("here is to console", this.data);

    var size = Object.keys(this.data).length;
    console.log("what is the sizw of obj", size);
    if (size != 0) {
      this.data.password = this._EncryptDecryptService.decrypt(this.data.password)
      this.data.confirmPassword = this.data.password;
      // this.selectedModule = this.data.modules
      this.match = true
      this.passwordMismatch = false
      this.isDisable = false
      this.createForm(this.data)
    } else {
      this.createForm()
    }



    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
    });



  }



  // FORMCONTROLL

  createForm(details?) {
    console.log("what is details", details);

    this.addClientForm = this.formBuilder.group({
      email: new FormControl((details && details.email) ? details.email : '', [Validators.email]),
      password: new FormControl((details && details.password) ? details.password : ''),
      confirmPassword: new FormControl((details && details.confirmPassword) ? details.confirmPassword : ''),
      name: new FormControl((details && details.name) ? details.name : ''),
      modules: new FormControl((details && details.selectedModule) ? details.selectedModule : this.selectedModule)
    })

  }


  // UTILITY



  comparePassword(form) {
    console.log(form.value.password == form.value.confirmPassword, this.match);
    if (form.value.password === form.value.confirmPassword) {
      console.log("In true condition");
      this.match = true;
      this.passwordMismatch = false
    } else {
      this.match = false;
      this.passwordMismatch = true
    }

  }

  password() {
    this.show = !this.show;
    this.pwd = !this.pwd;
  }
  cpassword() {
    this.show1 = !this.show1;
    this.pwd1 = !this.pwd1;
  }


  compareObjects(o1: any, o2: any): boolean {
    // console.log("compareObjects called", o1._id, o2._id)
    if (o1 && o2) {
      return o1.value === o2.value;
    }

  }


  openDialog(someComponent, data = {}): Observable<any> {
    console.log("OPENDIALOG", "DATA = ", data);
    const dialogRef = this.dialog.open(someComponent, { data });
    return dialogRef.afterClosed();
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  // API CALL



  addClient() {

    this.loading = true;
    if (this.data && this.data.isEdit == true) {

      console.log("this is for edit client", this.addClientForm.value);

      let newPassword = this._EncryptDecryptService.encrypt(this.addClientForm.value.password)
      let newConPass = this._EncryptDecryptService.encrypt(this.addClientForm.value.confirmPassword)
      console.log("add new client form value", newPassword);
      console.log("confirm password", newConPass);



      // this.addClientForm.patchValue({
      //   password: newPassword,
      //   confirmPassword: newConPass
      // })


      let clientData = {
        name: this.addClientForm.controls['name'].value,
        email: this.addClientForm.controls['email'].value,
        password: newPassword,
        modules: this.addClientForm.controls['modules'].value
        // instanceUrl: this.data.instanceUrl
      }


      this._clientService.editClient(clientData, this.data._id).subscribe((response) => {
        console.log("response of new client", response);
        this.loading = false;
        this.dialogRef.close(response)

      }, err => {
        this.loading = false;
        console.log("error while add new client", err);
      })

    } else {

      let newPassword = this._EncryptDecryptService.encrypt(this.addClientForm.value.password)
      let newConPass = this._EncryptDecryptService.encrypt(this.addClientForm.value.confirmPassword)
      console.log("add new client form value", newPassword);
      console.log("confirm password", newConPass);

      // this.addClientForm.patchValue({
      //   password: newPassword,
      //   confirmPassword: newConPass
      // })

      let clientData = {
        name: this.addClientForm.controls['name'].value,
        email: this.addClientForm.controls['email'].value,
        password: newPassword,
        modules: this.addClientForm.controls['modules'].value
        // instanceUrl: this.data.instanceUrl
      }
      console.log("final client list", clientData);
      this._clientService.addClient(clientData).subscribe((response) => {
        console.log("response of new client", response);
        this.loading = false;
        this.dialogRef.close(response)
      }, err => {
        this.loading = false;
        console.log("error while add new client", err);
      })
    }
  }

  delete() {
    console.log("client data", this.data);

    let data = { display: 'Are you sure you want to delete this client?' }

    var addedlearner = this.openDialog(DeleteModelComponent, data).subscribe((client) => {
      console.log("learner added in controller = ", client);
      if (client == undefined) return
      if (client == 'yes') {

        let deletedData = {
          clientId: this.data._id
        }
        this.isloading = true;

        this._clientService.deleteClient(deletedData).subscribe((response) => {
          console.log("resposne of client delete", response);
          this.isloading = false;
        }, error => {
          this.isloading = false;
          console.log("error while delete client", error);
          if (error && error.data == true) {
            let data = { display: error.msg }
            var clientDelete = this.openDialog(DeleteModelComponent, data).subscribe((deleted) => {
              if (deleted == undefined) return
              if (deleted == 'yes') {

                console.log("now its final to deleted");

                let deletedData = {
                  clientId: this.data._id,
                  isDeleted: true
                }
                this.isloading = true;
                this._clientService.deleteClient(deletedData).subscribe((response) => {
                  console.log("response of client deleted", response);
                  let obj = {
                    deleted: true,
                    client: response
                  }
                  this.isloading = false;
                  this.dialogRef.close(obj)
                }, err => {
                  this.isloading = false;
                  console.log("error", err);

                })
              } else {

              }
            })
          }
        })
      } else {
        // console.log("ahi kai nai thay");

      }
    }, err => {
      console.log("error", err);



      return this.openSnackBar("Something went wrong!", "Ok");
    });





  }


}
