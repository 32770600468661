import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-error-logs',
  templateUrl: './error-logs.component.html',
  styleUrls: ['./error-logs.component.scss']
})
export class ErrorLogsComponent implements OnInit {

  @Input('errorList') listOfError
  errorsList = []


  constructor() { }

  ngOnInit() {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.listOfError && changes.listOfError.currentValue && changes.listOfError.currentValue.length) {
      this.errorsList = changes.listOfError.currentValue
      console.log("error list in small page", this.errorsList);

    }
  }

}
