console.log = function () { }

let envs = [];
let env = "production";

envs['development'] = {
    baseApiUrl: "http://localhost:5000/",
    key: "AKIAWVUMJVVMOY7TEFVE",
    secret: "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",
    bucket: "development-platinum-rail-services",
    region: "eu-west-2"
}

envs['testing'] = {
    baseApiUrl: "https://testing.platinumrailservices.co.uk:/api/",
    key: "AKIAWVUMJVVMOY7TEFVE",
    secret: "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",
    bucket: "testing-platinum-rail-services",
    region: "ap-south-1"
}

envs['production'] = {
    baseApiUrl: 'https://super-admin.platform-oms.co.uk/api/',
    key: "AKIAWVUMJVVMOY7TEFVE",
    secret: "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",
    bucket: "platinum-rail-services",
    region: "eu-west-2"
}

export const config = envs[env]; 