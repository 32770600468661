import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
declare var $;

@Component({
  selector: 'app-edit-client-modal',
  templateUrl: './edit-client-modal.component.html',
  styleUrls: ['./edit-client-modal.component.scss']
})
export class EditClientModalComponent implements OnInit {

  show: boolean;
  pwd: boolean;
  show1: boolean;
  pwd1: boolean;

  constructor(public dialogRef: MatDialogRef<EditClientModalComponent>) {
    
   }

  ngOnInit() {

    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
    });

  }

  password() {
    this.show = !this.show;
    this.pwd = !this.pwd;
  }
  cpassword() {
    this.show1 = !this.show1;
    this.pwd1 = !this.pwd1;
  }

}
