import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

import { LoginService } from '../services/login.service'
@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  @Output() btnClicked: EventEmitter<any> = new EventEmitter<any>();
  currentUser
  constructor(public dialog: MatDialog, private _loginService: LoginService) {
    this._loginService.userProfile.subscribe((res) => {
      console.log("response when new user login", res);
      this.currentUser = JSON.parse(localStorage.getItem("superAdmin"));
    })
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("superAdmin"));
  }

  emitEvent(value) {
    this.btnClicked.emit({ value: value })
    console.log("hello check event btn click!!");
  }

  openDialog(): void {
    this.dialog.open(ResetPasswordComponent, {
    });
  }

}
