import { Injectable } from '@angular/core';
import { Observable, observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { config } from '../config';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';





@Injectable({
  providedIn: 'root'
})
export class LoginService {

  @Output() userRole: EventEmitter<any> = new EventEmitter<any>();
  @Output() userProfile: EventEmitter<any> = new EventEmitter<any>();
  @Output() userToken: EventEmitter<any> = new EventEmitter<any>();
  @Output() userRolesSet: EventEmitter<any> = new EventEmitter<any>();

  private loggedInUser = new Subject<any>();
  private superAdminSubject: BehaviorSubject<any>;
  private userRoleSubject: BehaviorSubject<any>;
  public superAdmin: Observable<any>;
  public userRoles: Observable<any>
  returnUrl;
  tempUser: any;
  roles = {};


  constructor(public activeRoute: ActivatedRoute, private http: HttpClient, private router: Router) {



    console.log('localStorage', localStorage.getItem('superAdmin'));
    if (localStorage.getItem('superAdmin') != null) {
      console.log('Inside IF-----------');
      this.superAdminSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('superAdmin')));
      this.userRoleSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userRoles')))
    } else {
      this.superAdminSubject = new BehaviorSubject<any>(localStorage.getItem('superAdmin'));
      this.userRoleSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userRoles')))
    }

    this.superAdmin = this.superAdminSubject.asObservable();
    this.userRoles = this.userRoleSubject.asObservable();
  }

  public get superAdminValue(): any {
    return this.superAdminSubject.value;
  }
  public get userRolesValue(): any {
    return this.userRoleSubject.value
  }

  sendLoggedInUserToAppUser(jobs) {
    console.log("response of jobs", jobs);

    this.loggedInUser.next({ jobs })
  }
  getLoggedInUser(): Observable<any> {
    return this.loggedInUser.asObservable();
  }



  login(data: any, token): Observable<any> {
    data.recaptchaToken = token;
    console.log("Adding clients", data);
    // console.log('Route name:');

    return new Observable<any>((observer) => {
      console.log("Observable");
      var that = this;

      this.http.post(config.baseApiUrl + "user/login", data).subscribe((res: any) => {
        console.log("what is in this response", res);

        localStorage.setItem('superAdminToken', res.token);
        localStorage.setItem('superAdmin', JSON.stringify(res.profile));

        this.setUserRoles()

        this.superAdminSubject.next(res.profile);

        // this.userRole.emit(res.userRole);
        this.userProfile.emit(res.profile);
        this.userToken.emit(res.token);
        observer.next(res.profile);
        observer.complete();
        return res.profile;
      }, err => {
        console.log("ERROR", err)
        observer.error(err);
      }, () => {
        console.log("CALL COMPLETED ")
        observer.complete();
        $(document).ready(function () {
          $(".sidebar").show();
          $("#topnav").show();
        });
      })
    });

  }

  setUserRoles() {
    console.warn("SETTING ROLE")
    let tempUser = JSON.parse(localStorage.getItem('superAdmin'))
    if (tempUser && tempUser.userRole) {
      console.warn("SETTING ROLE IN IF")
      this.userRoleSubject.next(this.roles)
    }

  }
  logout(stateUrl?: Boolean) {
    console.log('Logout is called', stateUrl);
    this.tempUser = localStorage.superAdmin ? JSON.parse(localStorage.superAdmin) : null
    this.superAdminSubject.next(null);
    // console.log("this.tempUser['userRole']", this.tempUser['userRole'][0])
    if (this.tempUser) {
      switch (this.tempUser['userRole'][0]) {
        case 'admin':
          if (stateUrl) {
            this.router.navigate(['/login/admin'], { queryParams: { returnUrl: this.router.url } });
          } else {
            this.router.navigate(['/login/admin']);
          }
          localStorage.clear();
          break;
        default:
          if (stateUrl) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
          } else {
            this.router.navigate(['/login']);
          }
          localStorage.clear();
          break;

        // case 'instructor':
        //   if (stateUrl) {
        //     this.router.navigate(['/login/staff'], { queryParams: { returnUrl: this.router.url } });
        //   } else {
        //     this.router.navigate(['/login/staff']);
        //   }
        //   localStorage.clear();
        //   break;
        // case 'learner':
        //   if (stateUrl) {
        //     this.router.navigate(['/login/learners'], { queryParams: { returnUrl: this.router.url } });
        //   } else {
        //     this.router.navigate(['/login/learners']);
        //   }
        //   localStorage.clear();
        //   break;
        // case 'client':
        //   if (stateUrl) {
        //     this.router.navigate(['/login/clients'], { queryParams: { returnUrl: this.router.url } });
        //   } else {
        //     this.router.navigate(['/login/clients']);
        //   }
        //   localStorage.clear();
        //   break;
        // case 'mp':
        //   if (stateUrl) {
        //     this.router.navigate(['/login/mp'], { queryParams: { returnUrl: this.router.url } });
        //   } else {
        //     this.router.navigate(['/login/mp']);
        //   }
        //   localStorage.clear();
        //   break;
      }
    }

    $(document).ready(function () {
      $(".sidebar").hide();
      $("#topnav").hide();
    });
  }


  forgotPassword(data: any, routename): Observable<any> {
    console.log("forgotpassword", data);
    return new Observable<any>((observer) => {
      console.log("Observable");
      var that = this;
      this.http.post(config.baseApiUrl + "" + data.user + "/forgot-password", data).subscribe((res: any) => {
        observer.next(res.data);
      }, err => {
        console.log("ERROR ")
        observer.error(err);
      }, () => {
        console.log("CALL COMPLETED ")
        observer.complete();
      })
    });
  }

  resetPassword(data: any, routename): Observable<any> {
    console.log("reset password", data, routename);
    return new Observable<any>((observer) => {
      console.log("Observable");
      var that = this;
      this.http.post(config.baseApiUrl + "" + routename + "/reset-password", data).subscribe((res: any) => {
        observer.next(res.data);
        // observer.complete();
      }, err => {
        console.log("ERROR ", err)
        observer.error(err);
      }, () => {
        console.log("CALL COMPLETED ")
        observer.complete();
      })
    });

  }


}
