import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { ClientsService } from '../services/clients.service';
import { DeleteModelComponent } from '../common/delete-model/delete-model.component';
import { Observable } from 'rxjs';
declare var $;

@Component({
  selector: 'app-add-admin-modal',
  templateUrl: './add-admin-modal.component.html',
  styleUrls: ['./add-admin-modal.component.scss']
})
export class AddAdminModalComponent implements OnInit {

  show: boolean;
  pwd: boolean;
  show1: boolean;
  pwd1: boolean;
  adminForm
  match = false
  passwordMismatch
  error: any;
  loading
  isloading: boolean;
  isDisable = false
  constructor(
    public _EncryptDecryptService: EncryptDecryptService,
    public _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // public _learnerService: LearnerService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public _clientService: ClientsService
  ) {

    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
    });

  }

  ngOnInit() {

    console.log("data ", this.data);
    var size = Object.keys(this.data).length;
    console.log("what is the sizw of obj", size);
    if (this.data && this.data.isEdit == true) {
      this.data.password = this._EncryptDecryptService.decrypt(this.data.password)
      this.data.confirmPassword = this.data.password;
      // this.selectedModule = this.data.modules
      this.match = true
      this.passwordMismatch = false
      // this.isDisable = false
      this.createForm(this.data)
    } else {
      this.createForm()
    }

    // this.createForm()

  }








  // FORMCONTROLL

  createForm(details?) {
    console.log("what is details", details);

    this.adminForm = this.formBuilder.group({
      email: new FormControl((details && details.email) ? details.email : '', [Validators.email]),
      password: new FormControl((details && details.password) ? details.password : ''),
      confirmPassword: new FormControl((details && details.confirmPassword) ? details.confirmPassword : ''),
      name: new FormControl((details && details.name) ? details.name : ''),
      // modules: new FormControl((details && details.modules) ? details.modules : this.selectedModule)
    })

  }


  // UTILITY




  comparePassword(form) {
    console.log(form.value.password == form.value.confirmPassword, this.match);
    if (form.value.password === form.value.confirmPassword) {
      console.log("In true condition");
      this.match = true;
      this.passwordMismatch = false
    } else {
      this.match = false;
      this.passwordMismatch = true
    }

  }

  compareObjects(o1: any, o2: any): boolean {
    // console.log("compareObjects called", o1._id, o2._id)
    if (o1 && o2) {
      return o1.value === o2.value;
    }

  }


  openDialog(someComponent, data = {}): Observable<any> {
    console.log("OPENDIALOG", "DATA = ", data);
    const dialogRef = this.dialog.open(someComponent, { data });
    return dialogRef.afterClosed();
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  password() {
    this.show = !this.show;
    this.pwd = !this.pwd;
  }

  confirmPassword() {
    this.show1 = !this.show1;
    this.pwd1 = !this.pwd1;
  }




  // API CALL

  addAdmin() {
    console.log("admin form value", this.adminForm.value);

    this.loading = true;
    if (this.data && this.data.isEdit == true) {
      // console.log("this is for edit client", this.adminForm.value);

      let newPassword = this._EncryptDecryptService.encrypt(this.adminForm.value.password)
      let newConPass = this._EncryptDecryptService.encrypt(this.adminForm.value.confirmPassword)
      console.log("add new client form value", newPassword);
      console.log("confirm password", newConPass);

      // this.addClientForm.patchValue({
      //   password: newPassword,
      //   confirmPassword: newConPass
      // })
      let adminData = {
        name: this.adminForm.controls['name'].value,
        email: this.adminForm.controls['email'].value,
        password: newPassword,
        instanceUrl: this.data.instanceUrl,
        nodePort: this.data.nodePort
      }




      this._clientService.editAdmin(adminData, this.data._id).subscribe((response) => {
        console.log("response of new client", response);
        this.dialogRef.close(response)
        this.loading = false;
      }, err => {
        console.log("error while add new client", err);
        this.loading = false;
      })

    } else {




      let newPassword = this._EncryptDecryptService.encrypt(this.adminForm.value.password)
      let newConPass = this._EncryptDecryptService.encrypt(this.adminForm.value.confirmPassword)

      // this.adminForm.patchValue({
      //   password: newPassword,
      //   confirmPassword: newConPass
      // })

      let adminData = {
        name: this.adminForm.controls['name'].value,
        email: this.adminForm.controls['email'].value,
        password: newPassword,
        instanceUrl: this.data.instanceUrl,
        nodePort: this.data.nodePort
      }

      this._clientService.addAdmin(adminData).subscribe((response) => {
        this.dialogRef.close(response)
        this.loading = false;
      }, error => {
        console.log("error while add admin", error);
        this.error = error.msg ? error.msg : error
        this.loading = false;
      })

    }
  }


  delete() {

    let data = { display: 'Are you sure you want to delete this admin?' }
    this.isloading = true;
    var addedlearner = this.openDialog(DeleteModelComponent, data).subscribe((deletAdmin) => {
      console.log("learner added in controller = ", deletAdmin);
      if (deletAdmin == undefined) return
      if (deletAdmin == 'yes') {
        console.log("now its final to deleted");

        let deletedData = {
          adminId: this.data._id,
          isDeleted: true,
          instanceUrl: this.data.instanceUrl,
          nodePort: this.data.nodePort
        }

        this._clientService.deleteAdmin(deletedData).subscribe((response) => {
          this.isloading = false;
          console.log("response of client deleted", response);
          let obj = {
            deleted: true,
            admin: response
          }
          this.dialogRef.close(obj)
        }, err => {
          this.isloading = false;
          console.log("error", err);

        })
      }
    }, err => {
      console.log("error", err);
      return this.openSnackBar("Something went wrong!", "Ok");
    });
  }

}
