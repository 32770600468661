import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs/';
import { map, catchError, tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
// import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from './services/login.service';



@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    currentUser: any;
    constructor(public _loginService: LoginService, private route: ActivatedRoute,
        private router: Router, ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const accessToken = localStorage.getItem('superAdminToken');
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

        if (accessToken) {
            const cloned = req.clone({
                headers: req.headers.set("authorization", accessToken)
            });



            return next.handle(cloned)
                .pipe(map((response: HttpResponse<any>) => {
                    return response;
                }),

                    // Cath Error 400 Bad Request
                    catchError((error: HttpErrorResponse) => {
                        const errorMessage = error
                        // console.log("CONNECTION REFUSED ERROR", error)
                        // if(error.status === 0){
                        //     // this._loginService.logout(true)
                        //     this.router.navigate(['/server-error', {maintenance:'maintenance'}]);
                        // }
                        if (error.status === 401) {
                            console.log('Error Occured Status 401', error);
                            // this.router.navigate(['/server-error']);
                            this._loginService.logout(true)
                            // this.router.navigate(['/server-error']);
                        } else if (error.status === 400) {
                            console.log('Error Occured Status 400', error);
                        } else if (error.status === 500) {
                            console.log('Error Occured Status 500', error);
                            // alert("Something went wrong! Please try again later.")
                            this.handleSnackBar({ msg: "Something went wrong! Please try again later.", button: "Ok" });
                        } else if (error.status === 403) {
                            this.handleBar({ msg: "You do not have access to the requested data. Contact admin for further details.", button: "Ok" });
                        }
                        return throwError(error.error);
                    })
                );
        } else {
            return next.handle(req)
                .pipe(
                    map((event: HttpResponse<any>) => {
                        return event;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        // let errorMessage = error.error.message;
                        let errorMessage = error;

                        if (error.status === 401) {

                            this.router.navigate(['/login/admin']);
                        }
                        return throwError(error);
                    })
                );
        };

    }

    handleSnackBar(data) {
        this.openSnackBar(data.msg, data.button);
    }

    openSnackBar(message: string, action: string) {
        // this._snackBar.open(message, action, {
        //     duration: 2000,
        // });
    }

    handleBar(data) {
        this.openBar(data.msg, data.button);
    }

    openBar(message: string, action: string) {
        //     this._snackBar.open(message, action, {
        //         duration: 8000,
        //     });
    }
}