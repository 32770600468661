import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { ClientListComponent } from './client-list/client-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { AddClientComponent } from './add-client/add-client.component';
import { EditClientModalComponent } from './edit-client-modal/edit-client-modal.component';
import { SingleClientComponent } from './single-client/./single-client.component';

const routes: Routes = [
  {
    path: '',
    redirectTo:'/login',
    pathMatch: "full",
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'client-list',
    component: ClientListComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'add-client',
    component: AddClientComponent
  },
  {
    path: 'edit-client',
    component: EditClientModalComponent
  },
  {
    path: 'client/:id',
    component: SingleClientComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
