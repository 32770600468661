import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule, NgForm } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha'
import { MatBadgeModule, MatTooltipModule, MatSelectModule, MatDialogModule, MatSidenavModule, MatButtonModule, MatCheckboxModule, MatPaginatorModule, MatToolbarModule, MatInputModule, MatProgressSpinnerModule, MatCardModule, MatMenuModule, MatIconModule, MatDatepickerModule, MatNativeDateModule, MAT_DATE_LOCALE, MatGridListModule, MatTableModule, MatSortModule, MAT_DATE_FORMATS, MatChipsModule, MatDialogRef, MAT_DIALOG_DATA, MatSlideToggleModule } from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthInterceptor } from './intercaptor';




import { TopNavComponent } from './top-nav/top-nav.component';
import { ClientListComponent } from './client-list/client-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { AddClientComponent } from './add-client/add-client.component';
import { PaymentComponent } from './payment/payment.component';
import { ErrorLogsComponent } from './error-logs/error-logs.component';
import { EditClientModalComponent } from './edit-client-modal/edit-client-modal.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SingleClientComponent } from './single-client/single-client.component';
import { DeleteModelComponent } from './common/delete-model/delete-model.component';
import { AddAdminModalComponent } from './add-admin-modal/add-admin-modal.component';
import { NoDataMsgComponent } from './common/no-data-msg/no-data-msg.component';

@NgModule({
  declarations: [
    AppComponent,
    ClientListComponent,
    TopNavComponent,
    DashboardComponent,
    LoginComponent,
    AddClientComponent,
    PaymentComponent,
    ErrorLogsComponent,
    EditClientModalComponent,
    ResetPasswordComponent,
    SingleClientComponent,
    DeleteModelComponent,
    AddAdminModalComponent,
    NoDataMsgComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    HttpClientModule,
    MatListModule,
    MatPaginatorModule,
    MatTabsModule,
    MatSelectModule, MatTableModule, MatSnackBarModule, MatButtonModule, MatDialogModule, MatCheckboxModule, MatToolbarModule, MatInputModule, MatProgressSpinnerModule, MatCardModule, MatMenuModule, MatIconModule, MatDatepickerModule, MatNativeDateModule, MatGridListModule, MatChipsModule, MatSortModule,
    MatSidenavModule, MatTooltipModule, MatSlideToggleModule, MatAutocompleteModule,
    NgbModule,
  ],

  entryComponents: [
    AddClientComponent,
    EditClientModalComponent,
    ResetPasswordComponent,
    DeleteModelComponent,
    AddAdminModalComponent
  ],

  providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Leb9hgiAAAAAA9Nk_GlJiwigblF_H6qHz4slrou' },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }
    ,
  {
    provide: MatDialogRef,
    useValue: {}
  },
  {
    provide: MAT_DIALOG_DATA,
    useValue: {}
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
