import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../services/clients.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { AddAdminModalComponent } from '../add-admin-modal/add-admin-modal.component';
import { Observable } from 'rxjs';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';

@Component({
  selector: 'app-single-client',
  templateUrl: './single-client.component.html',
  styleUrls: ['./single-client.component.scss']
})
export class SingleClientComponent implements OnInit {


  clientId
  singleClientDetails
  errorLogs = []
  adminList = []
  bgColors = ["bg-green", "bg-pink", "bg-teal", "bg-orange", "bg-blue", "bg-red"];
  noDataMsg = {
    title: "No Admins!",
    data: "You have not yet created any admin"
  }
  noDataMsg1 = {
    title: "No Error Logs!",
    data: "You have not yet any errors"
  }
  displayModule
  loading = false
  isDisable = false
  isEnable = true
  constructor(
    public _clientService: ClientsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public _snackBar: MatSnackBar,
    public _EncryptDecryptService: EncryptDecryptService,
  ) {
    this.activatedRoute.params.subscribe((param) => {
      console.log("what is param value", param);
      if (param && param.id) {
        this.clientId = param.id

        this.getSingleClient(this.clientId)
      }
    })

  }

  ngOnInit() {


  }


  // UTILITY



  handleSnackBar(data) {
    this.openSnackBar(data.msg, data.button);
  }

  openDialog(someComponent, data = {}): Observable<any> {
    console.log("OPENDIALOG", "DATA = ", data);
    const dialogRef = this.dialog.open(someComponent, { data });
    return dialogRef.afterClosed();
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  addAdmin() {
    let data = {
      instanceUrl: this.singleClientDetails.instanceUrl,
      nodePort: this.singleClientDetails.nodePort
    }

    var addedlearner = this.openDialog(AddAdminModalComponent, data).subscribe((admin) => {
      console.log("learner added in controller = ", admin);
      if (admin != undefined) {
        this.adminList.push(admin.data)
        // this.clients.push(client)
        // this.updateData(this.clients)
        this.openSnackBar("Admin added successfully!", "Ok");
      }
    }, err => {
      return this.openSnackBar("Something went wrong!", "Ok");
    });
  }


  editAdmin(item) {
    console.log("edit admin data", item);
    item['isEdit'] = true
    item['instanceUrl'] = this.singleClientDetails.instanceUrl
    item['nodePort'] = this.singleClientDetails.nodePort
    let data = item

    var addedlearner = this.openDialog(AddAdminModalComponent, data).subscribe((admin) => {
      console.log("learner added in controller = ", admin);
      if (admin == undefined) {
        let index = this.adminList.findIndex((x) => x._id == item._id)
        item.password = this._EncryptDecryptService.encrypt(item.password)
        console.log("what is index", index);
        if (index > -1) {
          console.log("what is item", item);
          this.adminList[index] = item
        }
      }
      if (admin != undefined && !admin.deleted) {
        let index = this.adminList.findIndex((x) => x._id == admin.data._id)
        this.adminList[index] = admin.data
        // this.clients.push(client)
        // this.updateData(this.clients)
        this.openSnackBar("Admin edited successfully!", "Ok");
      }
      if (admin && admin.deleted == true) {

        let index = this.adminList.findIndex(x => x._id === admin._id);

        this.adminList.splice(index, 1)
        // this.updateData(this.clients)
        this.handleSnackBar({ msg: "Admin deleted successfully!", button: "Ok" });
      }
    }, err => {
      return this.openSnackBar("Something went wrong!", "Ok");
    });

  }

  getRandomColorClass(index) {
    // let i = Math.floor(Math.random() * this.bgColors.length);
    var rand = Math.floor(Math.random() * this.bgColors.length);
    rand = index % 6;
    // console.log("what is rnd", rand);

    // this.lastColor = rand;
    // console.log("RETURNING", this.bgColors[rand]);

    return this.bgColors[rand];
  }


  chanegeModule(event) {
    console.log("what is the event", event);

    if (event.checked == true) {
      this.isDisable = true
      this.displayModule = 'Module Enable'
      let obj = {
        clientId: this.singleClientDetails._id,
        isEnabled: true
      }
      this._clientService.instancePermission(obj).subscribe((response) => {
        console.log("response of module permission", response);
        this.singleClientDetails['isEnabled'] = response.isEnabled
        this.isDisable = false
      }, err => {
        console.log("error while chage module", err);
        this.isDisable = false
      })
    } else {
      this.displayModule = 'Module Disable'
      this.isDisable = true
      let obj = {
        clientId: this.singleClientDetails._id,
        isEnabled: false
      }
      this._clientService.instancePermission(obj).subscribe((response) => {
        console.log("response of module permission", response);
        this.singleClientDetails['isEnabled'] = response.isEnabled
        this.isDisable = false
      }, err => {
        console.log("error while chage module", err);
        this.isDisable = false
      })
    }


  }

  // API CALL


  getSingleClient(clientId) {
    this.loading = true
    this._clientService.getSingleClient(clientId).subscribe((response) => {
      console.log("response of single client data", response);
      this.singleClientDetails = response
      this.isEnable = true
      if (this.singleClientDetails && this.singleClientDetails.errorLogs && this.singleClientDetails.errorLogs.errorLogs && this.singleClientDetails.errorLogs.errorLogs.length) {
        this.errorLogs = this.singleClientDetails.errorLogs.errorLogs
      }
      if (this.singleClientDetails && this.singleClientDetails.adminList && this.singleClientDetails.adminList.length) {
        this.adminList = this.singleClientDetails.adminList
      }
      if (this.singleClientDetails && this.singleClientDetails.isEnabled == true) {
        this.displayModule = 'Module Enable'
      } else {
        this.displayModule = 'Module Disable'
      }
      this.loading = false
    }, err => {
      console.log("erro while get single client data", err);
      this.loading = false
      this.isEnable = false
      this.singleClientDetails = err.data.clientUniqId
    })


  }

}
