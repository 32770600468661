import { Component, ViewChild } from '@angular/core';
import { from } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav'
import { LoginService } from './services/login.service'
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Platinum-Admin-Panel';
  currentUser
  user
  disabled: boolean;
  constructor(private _loginService: LoginService) {
    this._loginService.userProfile.subscribe((res) => {
      console.log("response when new user login", res);
      this.currentUser = JSON.parse(localStorage.getItem("superAdmin"));
    })
  }
  ngOnInit(): void {

    $('.collapseToggle').on('click', function () {
      $(".sidebar").toggleClass('sidebar--Collapse');
      $('.main').toggleClass('menu--open');
      $('body').toggleClass('overflow_hidden');
      $('#toggleIcon').toggleClass('rotate');
      $(".sidebar").hasClass('sidebar--Collapse') ? $(".tooltip-class").css({ 'display': 'block' }) : $(".tooltip-class").css({ 'display': 'none' });
      $('.sidebar ul li a').click(function () {
        var windowWidth = $(window).width();
        if (windowWidth < 1025) {
          $(".sidebar").addClass('sidebar--Collapse');
          $('.main').removeClass('menu--open');
          $('body').removeClass('overflow_hidden');
          $('#toggleIcon').addClass('rotate');
        }
      });
    });
    this.currentUser = JSON.parse(localStorage.getItem("superAdmin"));
  }

  getToolTip() {
    if ($(".sidebar").hasClass('sidebar--Collapse')) {
      this.disabled = true;
    }
    else {
      this.disabled = false;
    }
    return $(".sidebar").hasClass('sidebar--Collapse') ? 'expand' : 'collapse';
  }

  Logout() {
    $(".main").css({ 'padding': '100px 20px 20px' });
    console.log('Logout is called');
    // this.loading = true
    this._loginService.logout();
    
  }

  eventFromTitleBar(event) {
    console.log("event on itm clicked", event)
    console.log("this.user", this.user)
    if (event.value == 'logout') {
      this.Logout()
      // localStorage.clear();
    }

  }


}
