import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-no-data-msg',
  templateUrl: './no-data-msg.component.html',
  styleUrls: ['./no-data-msg.component.scss']
})
export class NoDataMsgComponent implements OnInit {


  @Input('msg') msg;
  @Input('icon') icon;

  constructor() { }

  ngOnInit() {
  }

// }

ngOnChanges(changes: SimpleChanges): void {
  console.log("changes", changes)
  if(changes.msg){
    console.log("changes.msg", changes.msg)
    this.msg = changes.msg.currentValue
  }
  if(changes.icon){
    this.icon = changes.icon.currentValue
  }
}

}
