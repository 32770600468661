import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AddClientComponent } from '../add-client/add-client.component';
import { data } from 'jquery';
import { ClientsService } from '../services/clients.service';
import { FilterService } from '../filter.service'
import { from, Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditClientModalComponent } from '../edit-client-modal/edit-client-modal.component';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
// import { runInThisContext } from 'vm';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  paginator: MatPaginator;
  displayedColumns: string[] = ['name', 'email', 'modules', 'actions'];
  sort: MatSort;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  clients: any[];
  loading: boolean = false;
  bgColors: string[];
  lastColor;
  searchText: string;
  noDataMsg = {
    title: "No Clients!",
    data: "You have not yet created any client"
  }

  @ViewChild(MatPaginator, { static: true }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatSort, { static: true }) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log("table data check ==>", this.dataSource.sort)
  }

  constructor(
    public _clientService: ClientsService,
    public dialog: MatDialog,
    public _snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _filterService: FilterService,
    public _EncryptDecryptService: EncryptDecryptService,
  ) {
    this.clients = [];
    this.dataSource = new MatTableDataSource(this.clients);
    console.log("check data source==>", this.dataSource)
    this.bgColors = ["bg-red", "bg-blue", "bg-teal", "bg-pink", "bg-purple", "bg-orange"];
  }

  ngOnInit() {
    this.loading = true;
    this.getClients();
    const paginatorIntl = this.paginator._intl;
    paginatorIntl.nextPageLabel = '';
    paginatorIntl.previousPageLabel = '';

  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  getRandomColorClass(i) {
    var rand = Math.floor(Math.random() * this.bgColors.length);
    rand = i % 6;
    this.lastColor = rand;
    return this.bgColors[rand];
  }

  clientPortal(client) {
    console.log('client', client)
    if (client && client.instanceUrl != "") {
      this.loading = true;
      let obj = {
        client: client,
        superAdmin: JSON.parse(localStorage.getItem('superAdmin'))
      }
      this._clientService.redirectToPortal(obj).subscribe((res) => {
        console.log('data in comp', res)
        if (res && res.status == 404) {
          this.openSnackBar("Client Instance does not exist", "Ok");
        } else {
          if (res && res.token && res.profile) {


            let token = res.token

            let profile = JSON.stringify(res.profile)
            let userToPass = JSON.parse(localStorage.getItem('superAdmin'))

            userToPass['modules'] = res.profile.modules
            // localStorage.setItem('token',token)
            let superAdmin = {
              token: res.token,
              user: userToPass
              // modules: 
            }
            console.log("supser admin data", superAdmin);
            let url = client.instanceUrl
            let newWindow = window.open(url + '/login/super-admin?superAdmin=' + JSON.stringify(superAdmin))
          } else {
            this.openSnackBar("Client Instance does not exist", "Ok");
          }
          this.loading = false;
        }
      }, error => {
        console.log("error while redirection", error);
        this.loading = false
        this.openSnackBar("Client Instance does not exist", "Ok");
      })
    } else {
      this.openSnackBar("Client Instance does not exist", "Ok");
    }
  }

  // Utility Functions
  updateData(clients) {
    console.log("UPDATING DATA = ", clients)
    console.log("check data souce accessor workk or not", this.dataSource)
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      console.log("item=====>>>>>", data);
      console.log("property=====>>>>>", sortHeaderId);

      switch (sortHeaderId) {
        case 'name':
          return data.name;
        case 'email': return data.email;
        default:
          break;
      }
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log("SETTING SORT TO = ", this.dataSource.sort)
    console.log("SETTING paginator TO = ", this.dataSource.paginator)
    // this.loading=false;

  }

  filter(searchText) {

    let tempDataSource = this._filterService.filter(searchText, this.clients, ['name']);
    this.dataSource = tempDataSource;
    console.log("check  this filter data==>", tempDataSource)
  }



  handleSnackBar(data) {
    this.openSnackBar(data.msg, data.button);
  }

  openDialog(someComponent, data = {}): Observable<any> {
    console.log("OPENDIALOG", "DATA = ", data);
    const dialogRef = this.dialog.open(someComponent, { data });
    return dialogRef.afterClosed();
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }


  /**
   * Open model for add new client
   */
  addNewClient() {
    console.log("here is to open model");



    var addedlearner = this.openDialog(AddClientComponent).subscribe((client) => {
      console.log("learner added in controller = ", client);
      if (client != undefined) {
        this.clients.push(client)
        this.updateData(this.clients)
        this.openSnackBar("Client added successfully!", "Ok");
      }
    }, err => {
      return this.openSnackBar("Something went wrong!", "Ok");
    });

  }


  editClient(client) {
    console.log("edit client data", client);
    client['isEdit'] = true
    var addedlearner = this.openDialog(AddClientComponent, client).subscribe((clientUpdate) => {
      console.log("learner added in controller = ", client);
      if (clientUpdate != undefined) {
        if (clientUpdate && clientUpdate.deleted == true) {

          let index = this.clients.findIndex(x => x._id === clientUpdate._id);

          this.clients.splice(index, 1)
          this.updateData(this.clients)
          this.handleSnackBar({ msg: "Client deleted successfully!", button: "Ok" });
        } else {
          let index = this.clients.findIndex(x => x._id === clientUpdate._id);
          this.clients[index] = clientUpdate
          // this.clients.push(client)
          this.updateData(this.clients)
          this.handleSnackBar({ msg: "Client edited successfully!", button: "Ok" });
        }
      } else {
        let index = this.clients.findIndex((x) => x._id == client._id)
        client.password = this._EncryptDecryptService.encrypt(client.password)
        console.log("what is index", index);
        if (index > -1) {
          console.log("what is client", client);
          this.clients[index] = client
          this.updateData(this.clients)
        }
      }
    }, err => {
      return this.openSnackBar("Something went wrong!", "Ok");
    });
  }


  // API
  getClients() {
    this.loading = true;
    this._clientService.getClients().subscribe(clients => {
      console.log('clients from service in component', clients)
      this.clients = clients;
      this.dataSource = new MatTableDataSource(clients);

      this.loading = false;
      this.updateData(clients)
    })
  }


  redirectToClient(client) {
    console.log("single client data", client);
    this.router.navigate(['/client/' + client.id]);
  }


}
